import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks';
import Cookies from 'js-cookie';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';
import io from 'socket.io-client';

import { GLOBALS, CONFIG } from './constants';
import store from './redux';
import { setLang } from './redux/actions/locale.actions';
import { userLogin, userLogout } from './redux/actions/user.actions';
import { hideGlobalSnack } from './redux/actions/snack.actions';
import {
    isSupportedLanguage,
    getLanguageMessageList
} from './locales/locale.messages';
import { refreshUser } from './services/userServices';
import SocketContext from './socket';
import FeedDetails from './components/__pages/Feed/FeedDetails';
import Loading from './components/Loading';
import sitemap from './routing/siteMap';
import NotFound from './components/__pages/NotFound';
import Header from './components/__subpages/Header';
import Footer from './components/__subpages/Footer';
import Login, { ResetPasswordSubmit } from './components/__pages/Login';
import ResetPassword from './components/__pages/Login/ResetPassword';
import VeifyEmail from './components/__pages/Login/VerifyEmail';
import Home from './components/__pages/Home';
import Gallery from './components/__pages/Gallery';
import Exclusive from './components/__pages/Exclusive';
import Studios from './components/__pages/Studios';
import Feed from './components/__pages/Feed';
import Artist from './components/__pages/Artist';
import ArtWork from './components/__pages/ArtWork';
import EditArtwork from './components/__pages/User/EditArtwork';
// import User from "./components/__pages/User";
import GlobalDialog from './components/GlobalDialog';
import InitiateFollows from './components/__pages/User/InitiateFollows';
import Search from './components/__pages/Search';
import Wrapper from './Wrapper';
import FooterNavigation from './components/FooterNavigation';
import FollowingFollowers from './components/__pages/User/FollowingFollowers/FollowingFollowers';
import { Exhibition, Exhibitions } from './components/__pages/Exhibition';
import ArtistLikedArtwork from './components/__pages/Artist/ArtistLikedArtworks';
import UserArtwork from './components/__pages/ArtWork/UserArtwork';
import Notification from './components/__pages/User/Notification';
import GetStudio from './components/__pages/User/GetStudio';
import ArtistCode from './components/__pages/User/ArtistCode';
import Upload from './components/__pages/User/Upload';
import Edit from './components/__pages/User/Edit';
import { Cart } from './components/__pages/User/Cart';
import { Checkout } from './components/__pages/User/Cart';
import { Success } from './components/__pages/User/Cart';
import { Failure } from './components/__pages/User/Cart';
import LikedArtwork from './components/__pages/User/LikedArtwork';
import Message from './components/__pages/User/Message';
import Inbox from './components/__pages/User/Message/Inbox';
import Order from './components/__pages/User/Order';
import Profile from './components/__pages/User/Profile';
import Privacy from './components/__subpages/Footer/StaticPages/Privacy';
import sound from './assets/sounds/beep.mp3';
import Terms from './components/__subpages/Footer/StaticPages/TermsOfUse';
import PrivacySetting from './components/__pages/User/PrivacySetting/PrivacySetting';
import './styles/global.scss';
import './styles/theStyles.scss';
import { LoginRoute } from './routing/LoginRoute';
import NormalUser from './components/__pages/NormalUser/NormalUser';
import DeleteAccount from "./components/__pages/User/DeleteAccount";

setDefaultBreakpoints([{ s: 0 }, { m: 992 }]);

const snackStyles = {
    error: {
        background: red[500]
    }
};

const mapStateToProps = (state) => ({
    lang: state.locale.lang,
    snack: state.snack,
    user: state.user
});

class App extends Component {
    constructor() {
        super();

        this.state = {
            isUserRefreshInProgress: true,
            socketConnected: false,
            messageSeen: false,
            newMessage:false
        };

        this.hideFooterOnPages = [
            '/login',
            '/verify',
            '/resetpassword',
            /user\/*/g,
            /exhibition-\/*/g,
            /messages\/*/g,
            /cart\/*/g,
            /checkout\/*/g,
            '/notifications',
            '/upload',
            '/inbox',
            /studios\/*/g
        ];

        this.hideMobileFooter = [/exhibition-\/*/g];

        this.socket = undefined;

        const navigatorLanguage = (
            navigator.language ||
            navigator.browserLanguage ||
            GLOBALS.DEFAULT_SITE_LANGUAGE
        ).substring(0, 2);

        let lang = '';

        const languageCookie = Cookies.get(CONFIG.LANG_COOKIE_NAME);

        if (!languageCookie) {
            lang = isSupportedLanguage(navigatorLanguage)
                ? navigatorLanguage
                : GLOBALS.DEFAULT_SITE_LANGUAGE;
        } else {
            lang = languageCookie;
        }

        store.dispatch(setLang(lang));
    }
messageSeen=(seen)=>{

this.setState({messageSeen:seen})
}

    componentDidMount() {
        refreshUser(
            () => {
                this.setState({ isUserRefreshInProgress: true });
            },
            (user, token) => {
                store.dispatch(userLogin(user, token));
                this.setState({ isUserRefreshInProgress: false });
                this.connect();
            },
            (e) => {
                console.error(e);
                store.dispatch(userLogout());
                this.setState({ isUserRefreshInProgress: false });
            }
        );
    }

    componentDidUpdate(prevProps) {
        const { user, history } = this.props;
        const { isUserRefreshInProgress } = this.state;
        if (user && !prevProps.user) {
            this.connect();
        }
        // if (prevProps.isUserRefreshInProgress !== isUserRefreshInProgress) {
        //   if (!isUserRefreshInProgress && isEmpty(user)) {
        //     history.push(sitemap.login);
        //   }
        // }
    }
    ping = new Audio(sound);
    playSound = (audioFile) => {
        audioFile.play();
    };
    connect = () => {
        // let error = null;
        const { user } = this.props;
        this.socket = io(CONFIG.SOCKET_URL, {
            path: '/live/socket.io',
            transports: ['websocket', 'polling'],
            secure: true,
            rejectUnauthorized: false
        });
        this.socket.on('connect', () => {
            // re render App

            this.setState({
                socketConnected: true
            });
            this.socket.emit('userlogin', {
                id: user.id
            });
            this.socket.on('receive-message', (data) => {
                if (data?.room !== user?.id) {
               
                    this.playSound(this.ping);
                    this.setState({newMessage:true})
                }
            });
        });
    };

    closeSnack = () => {
        store.dispatch(hideGlobalSnack());
    };
    render() {
        const { lang, snack, classes, user } = this.props;
        const { isUserRefreshInProgress, socketConnected } = this.state;

        let appLoading = true;
        if (!user) {
            appLoading = false;
        } else if (user && socketConnected) {
            appLoading = false;
        }

        return (
          <IntlProvider locale={lang} messages={getLanguageMessageList(lang)}>
            <BrowserRouter>
              <BreakpointProvider>
                <CssBaseline />
                <SocketContext.Provider value={this.socket}>
                  {appLoading || isUserRefreshInProgress ? (
                    <div className="loader">
                      <Loading color="red" />
                    </div>
                  ) : (
                    <Wrapper
                      hideFooterOnPages={this.hideFooterOnPages}
                      hideMobileFooter={this.hideMobileFooter}
                    >
                      <Snackbar
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        open={snack.message !== ""}
                        autoHideDuration={snack.duration}
                        onClose={this.closeSnack}
                      >
                        <SnackbarContent
                          className={
                            snack.type === "error" ? classes.error : ""
                          }
                          message={<span id="message-id">{snack.message}</span>}
                          action={[
                            <IconButton
                              key="close"
                              aria-label="Close"
                              color="inherit"
                              onClick={this.closeSnack}
                            >
                              <CloseIcon />
                            </IconButton>,
                          ]}
                        />
                      </Snackbar>
                      <GlobalDialog />
                      {user && user.initiateFollows === "false" && (
                        <InitiateFollows />
                      )}
                      <Header
                        isUserRefreshInProgress={isUserRefreshInProgress}
                        messageSeen={this.state.messageSeen}
                      />
                      <div id="contentWrapper">
                        <Switch>
                          <Route exact path={sitemap.home} component={Home} />
                          <Route
                            exact
                            path={sitemap.deleteAccount}
                            component={DeleteAccount}
                          />
                          <Route path={sitemap.gallery} component={Gallery} />
                          <Route
                            path={sitemap.exclusive}
                            component={Exclusive}
                          />
                          <Route
                            exact
                            path={sitemap.studios}
                            component={Studios}
                          />

                          <Route path={sitemap.artist} component={Artist} />
                          <Route
                            path={sitemap.studiolikedArtwork}
                            component={ArtistLikedArtwork}
                          />
                          {/* <Route
                        path={sitemap.user}
                        render={(props) => (
                          <User
                            {...props}
                            isUserRefreshInProgress={isUserRefreshInProgress}
                          />
                        )}
                      /> */}
                          <LoginRoute
                            exact
                            path={sitemap.login}
                            component={Login}
                          />
                          {/* <Route
                        exact
                        path={sitemap.login}
                        component={!user ? Login : Home}
                      /> */}
                          <Route
                            exact
                            path={sitemap.resetPassword}
                            component={ResetPassword}
                          />
                          <Route
                            exact
                            path={sitemap.resetPasswordSubmit}
                            component={ResetPasswordSubmit}
                          />
                          <Route
                            exact
                            path={sitemap.verifyEmail}
                            component={VeifyEmail}
                          />
                          <Route
                            exact
                            path={sitemap.artWork}
                            component={ArtWork}
                          />
                          <Route
                            exact
                            path={sitemap.userArt}
                            component={UserArtwork}
                          />
                          <Route
                            exact
                            path={sitemap.artWorkEdit}
                            render={(props) => (
                              <EditArtwork
                                {...props}
                                isUserRefreshInProgress={
                                  isUserRefreshInProgress
                                }
                              />
                            )}
                          />
                          <Route
                            exact
                            path={sitemap.searchPage}
                            component={Search}
                          />
                          <Route
                            exact
                            path={sitemap.exhibitions}
                            component={Exhibitions}
                          />
                          <Route
                            exact
                            path={sitemap.exhibition}
                            component={Exhibition}
                          />
                          <Route
                            exact
                            path={sitemap.followingFollowers}
                            component={FollowingFollowers}
                          />
                          <Route
                            exact
                            path={sitemap.notFound}
                            component={NotFound}
                          />
                          <Route
                            path={sitemap.normalUser}
                            render={(props) => (
                              <NormalUser {...props} user={user} />
                            )}
                          />
                          <Route path={sitemap.privacy} component={Privacy} />
                          <Route path={sitemap.terms} component={Terms} />
                          <Route exact path={sitemap.feed} component={Feed} />

                          {user && (
                            <Route
                              exact
                              path={sitemap.feedDetails}
                              component={FeedDetails}
                            />
                          )}
                          {isUserRefreshInProgress ? (
                            <Loading color="red" />
                          ) : (
                            <>
                              {user ? (
                                <>
                                  <Route
                                    exact
                                    path={sitemap.notifications}
                                    render={(props) => (
                                      <Notification {...props} user={user} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={sitemap.getStudio}
                                    render={(props) => (
                                      <GetStudio {...props} user={user} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={sitemap.artistCode}
                                    component={ArtistCode}
                                  />
                                  <Route
                                    exact
                                    path={sitemap.uploadArt}
                                    render={(props) => (
                                      <Upload {...props} user={user} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={sitemap.editInformation}
                                    render={(props) => (
                                      <Edit {...props} user={user} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={sitemap.cart}
                                    render={(props) => (
                                      <Cart {...props} user={user} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={sitemap.checkout}
                                    render={(props) => (
                                      <Checkout {...props} user={user} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={sitemap.checkoutSuccess}
                                    render={(props) => (
                                      <Success {...props} user={user} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={sitemap.checkoutFailure}
                                    render={(props) => (
                                      <Failure {...props} user={user} />
                                    )}
                                  />
                                  <Route
                                    exact
                                    path={sitemap.userlikedArtwork}
                                    render={(props) => (
                                      <LikedArtwork {...props} user={user} />
                                    )}
                                  />
                                  <Route
                                    path={sitemap.messages}
                                    render={(props) => (
                                      <Message
                                        {...props}
                                        user={user}
                                        messageSeen={this.messageSeen}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={sitemap.inbox}
                                    render={(props) => (
                                      <Inbox
                                        {...props}
                                        user={user}
                                        messageSeen={this.messageSeen}
                                        newMessage={this.state.newMessage}
                                      />
                                    )}
                                  />
                                  <Route
                                    path={sitemap.order}
                                    render={(props) => (
                                      <Order {...props} user={user} />
                                    )}
                                  />
                                  <Route
                                    path={sitemap.profilePrivacy}
                                    render={(props) => (
                                      <PrivacySetting {...props} user={user} />
                                    )}
                                  />
                                  <Route
                                    path={sitemap.profile}
                                    render={(props) => (
                                      <Profile {...props} user={user} />
                                    )}
                                  />
                                </>
                              ) : (
                                <>
                                  <Redirect to="/" />
                                </>
                              )}
                            </>
                          )}

                          <Redirect to={sitemap.home} />
                        </Switch>
                      </div>
                      <Footer hideFooter={this.hideFooterOnPages} />
                      <FooterNavigation />
                    </Wrapper>
                  )}
                </SocketContext.Provider>
              </BreakpointProvider>
            </BrowserRouter>
          </IntlProvider>
        );
    }
}

App.propTypes = {
    lang: PropTypes.string.isRequired,
    snack: PropTypes.objectOf(PropTypes.any).isRequired,
    classes: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any),
    user: PropTypes.objectOf(PropTypes.any)
};

App.defaultProps = {
    user: null
};

export default connect(mapStateToProps)(withStyles(snackStyles)(App));
