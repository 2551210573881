export default {
  home: "/",
  login: "/login",
  resetPassword: "/resetpassword",
  resetPasswordSubmit: "/resetpassword/:token",
  verifyEmail: "/verify",
  gallery: "/gallery/:cat",
  exclusive: "/store/:cat",
  studios: "/studios",
  feed: "/feed",
  feedDetails: "/feed/:id",
  user: "/user",
  getStudio: "/:slug/get-studio",
  artistCode: "/:slug/artist-code",
  uploadArt: "/upload",
  uploadUserArt: "/userArt/upload",
  editInformation: "/edit",
  notifications: "/notifications",
  cart: "/cart",
  checkout: "/checkout",
  checkoutSuccess: "/checkout/success",
  checkoutFailure: "/checkout/failure",
  messages: "/messages/:to",
  inbox: "/inbox",
  artist: "/studios/:slug",
  artistWork: "/studios/:slug/work",
  artistFeed: "/studios/:slug/feed",
  normalUser: "/user/:slug",
  userWork: "/user/:slug/work",
  userFeed: "/user/:slug/feed",
  profile: "/profile/:slug",
  deleteAccount: "/delete-account",
  yourWork: "/profile/:slug/work",
  yourFeed: "/profile/:slug/feed",
  exhibitions: "/exhibitions",
  exhibition: "/exhibition-:slug",
  artWork: "/artwork/:slug",
  userArt: "/art/:slug",
  artWorkEdit: "/artwork/:slug/edit",
  searchPage: "/search/:term",
  likedArtwork: "/likedArt",
  userlikedArtwork: "/:slug/likedArt",
  studiolikedArtwork: "/studio/:slug/likedArt",
  order: "/order",
  profilePrivacy: "/privacy",
  followingFollowers: "/:slug/follows",
  followers: "/:slug/followers",
  following: "/:slug/following",
  privacy: "/privacy-policy",
  terms: "/terms-conditions",
  notFound: "/404",
};
