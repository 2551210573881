import React, { useState } from "react";
import { Input } from "../../../Form";
import "./Delete.scss";

let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const DeleteAccount = () => {
  const [reason, setReason] = useState("");
  const [email, setEmail] = useState("");
  const [validate, setValidate] = useState({
    invalidEmail: false,
    invalidReason: false,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email && reason && regex.test(email)) {
      let ATAG_NODE = document.createElement("a");
      ATAG_NODE.href = `mailto:dev.tulikaa@gmail.com?subject=${"Request For account Deletion"}&body=I would like to request to delete my account created with email "${email}". ${reason}`;
      ATAG_NODE.click();
    }
    setValidate({
      invalidEmail: !(email && regex.test(email)),
      invalidReason: !reason,
    });
  };

  return (
    <div id="delete-account">
      <section className="commonFormSection">
        <h3
          style={{
            textAlign: "center",
            marginBottom: "25px",
            fontWeight: "bold",
          }}
        >
          Send request to delete your account
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="typeTwoCol">
            <Input
              label="Email"
              type="text"
              name="email"
              value={email}
              subtitle={
                validate.invalidEmail && (
                  <span style={{ color: "red" }}>invalid email</span>
                )
              }
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              label="Reason"
              required
              type="text"
              name="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <button className="proceed-btn" onSubmit={handleSubmit}>
            Request Account Delete
          </button>
        </form>
      </section>
    </div>
  );
};

export default DeleteAccount;
